import ApiError from 'classes/ApiError';
import Banner from 'components/banner/Banner';
import { LoadingIndicator } from 'components/indicators/LoadingIndicator';
import ErrorPage from 'pages/ErrorPage';
import styled from 'styled-components';
import { font, maxWidthContent, readingWidth, spaceS } from 'styles/variables';
import { EPageErrorType } from 'types';

const PageContainer = styled.div`
    max-width: ${maxWidthContent};
`;

const HeaderSection = styled.section`
    display: flex;
    gap: ${spaceS};
    margin-bottom: ${spaceS};
`;
const TextContainer = styled.div`
    flex-shrink: 1;
    max-width: ${readingWidth};
`;

const Description = styled.p`
    margin-top: ${spaceS};
`;

const Header = styled.h1`
    font-family: ${font.bold};
    font-size: ${font.size.xl};
    line-height: ${font.lineHeight.xl};
`;

const HeaderSideContent = styled.div`
    flex: 1;
    justify-self: end;
`;

type Props = {
    title: string;
    description?: string | React.ReactNode;
    children: React.ReactNode;
    isLoading?: boolean;
    errors?: ApiError[];
    dataTestId?: string;
    headerContent?: React.ReactNode;
    pageKey?: string;
};

export default function Page({
    title,
    description,
    children,
    isLoading = false,
    errors,
    dataTestId,
    headerContent,
    pageKey,
}: Props): JSX.Element {
    let pageContent = children;
    if (errors && errors.length > 0) {
        if (errors.find((error) => !error.temporary)) {
            pageContent = <ErrorPage type={EPageErrorType.CRITICAL_ERROR_NO_MENU} />;
        } else {
            pageContent = <ErrorPage type={EPageErrorType.TEMPORARY} refresh />;
        }
    }

    return (
        <PageContainer data-testid={dataTestId}>
            <Banner pageKey={pageKey} />
            <HeaderSection>
                <TextContainer>
                    <Header>{title}</Header>
                    {description ? <Description data-testid="pageDescription">{description}</Description> : null}
                </TextContainer>
                {headerContent ? <HeaderSideContent>{headerContent}</HeaderSideContent> : null}
            </HeaderSection>
            {isLoading ? <LoadingIndicator displayBorder={false} /> : pageContent}
        </PageContainer>
    );
}
