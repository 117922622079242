import CrossIcon from 'assets/icons/cross.svg?react';
import WarningIcon from 'assets/icons/ic_24_warning_solid_white.svg?react';
import IconButton from 'components/clickables/IconButton';
import useBanner from 'hooks/useBanner';
import useLocalState from 'hooks/useLocalState';
import { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    black,
    borderRadiusM,
    font,
    mediumBlue,
    readingWidth,
    spaceL,
    spaceM,
    spaceS,
    spaceXs,
    white,
} from 'styles/variables';
import { Message } from 'types/inHubMessage';

const Container = styled.div`
    max-width: ${readingWidth};
    display: grid;
    grid-template-columns: ${spaceM} 1fr ${spaceM};
    column-gap: 0.75rem;
    background-color: ${black};
    border-radius: ${borderRadiusM};
    padding: ${spaceS};
    color: ${white};
    margin-bottom: ${spaceL};
`;

const Header = styled.h1`
    font-size: ${font.size.m};
    font-family: ${font.bold};
    margin-bottom: 2px;
    line-height: ${font.lineHeight.m};
`;

const Description = styled.p`
    margin: 0;
`;

const StyledWarningIcon = styled(WarningIcon)`
    fill: white;
`;

const StyledLink = styled.a`
    display: block;
    width: max-content;
    color: ${mediumBlue};
    margin-top: ${spaceXs};
    text-decoration: none;

    &:hover {
        color: ${mediumBlue};
    }
`;

const StyledIconButton = styled(IconButton)`
    margin-top: -${spaceXs};
    &:hover:not(:disabled) {
        background-color: unset;
    }
`;

const StyledCrossIcon = styled(CrossIcon)`
    path {
        fill: white;
    }
`;

type BannerProps = {
    pageKey?: string;
};

function Banner({ pageKey }: BannerProps): JSX.Element | null {
    const { i18n } = useTranslation();
    const {
        actions: { setBannerState },
        state: { bannerState },
    } = useLocalState();

    const selectedLanguage = i18n.language.replace('-', '_') as keyof Message;

    const { data: firestoreData } = useBanner();

    const isBannerVisible = useMemo(() => {
        return (
            !bannerState?.bannerHasBeenShown &&
            (firestoreData?.displayRoute === pageKey || firestoreData?.displayRoute === 'all')
        );
    }, [bannerState, firestoreData, pageKey]);

    useEffect(() => {
        if (firestoreData && firestoreData.created.toDate().valueOf() !== bannerState?.created?.valueOf()) {
            setBannerState({ created: firestoreData?.created.toDate(), bannerHasBeenShown: false });
        }
    }, [bannerState?.created, firestoreData, setBannerState]);

    if (!isBannerVisible) {
        return null;
    }

    return (
        <Container>
            <StyledWarningIcon data-testid="warningIcon" />
            <div>
                <Header data-testid="bannerHeader">{firestoreData?.titles[selectedLanguage]}</Header>
                <Description data-testid="descriptionContent">
                    {firestoreData?.messages[selectedLanguage]}
                    {firestoreData?.externalUrl ? (
                        <StyledLink data-testid="externalUrl" target="_blank" href={firestoreData?.externalUrl}>
                            {firestoreData?.linkText[selectedLanguage]}
                        </StyledLink>
                    ) : null}
                </Description>
            </div>
            {firestoreData?.dismissible && (
                <StyledIconButton
                    data-testid="dismissButton"
                    onClick={() =>
                        setBannerState({ created: firestoreData.created.toDate(), bannerHasBeenShown: true })
                    }
                >
                    <StyledCrossIcon />
                </StyledIconButton>
            )}
        </Container>
    );
}

export default memo(Banner);
