import { useQuery } from '@tanstack/react-query';
import { db } from 'firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { InHubMessage } from 'types/inHubMessage';

type useBannerReturnProps = {
    data?: InHubMessage;
    isLoading: boolean;
    error: Error | null;
};

const useBanner = (): useBannerReturnProps => {
    const fetchDocument = async (): Promise<InHubMessage> => {
        const docRef = doc(db, 'inHubMessage', 'message');
        const docSnap = await getDoc(docRef);

        return docSnap.data() as InHubMessage;
    };
    const {
        data,
        isFetching: isLoading,
        error,
    } = useQuery({
        queryKey: [`firebaseBanner`],
        queryFn: fetchDocument,
    });

    return {
        data,
        isLoading,
        error,
    };
};

export default useBanner;
