import Button from 'components/clickables/Button';
import useAuthentication from 'hooks/useAuthentication';
import useLocalState from 'hooks/useLocalState';
import { useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'types';
import { ETrackingEvent } from 'types/tracking';

type LogoutButtonProps = {
    className?: string;
    variant?: ButtonVariant;
};

function LogoutButton({ className, variant }: LogoutButtonProps): JSX.Element {
    const { logoutRedirectRegular } = useAuthentication();
    const { t } = useTranslation();

    const {
        state: { bannerState },
        actions: { setBannerState },
    } = useLocalState();

    const logoutAndReturn = useCallback(() => {
        ReactGA.event(ETrackingEvent.LOGOUT);
        setBannerState({ ...bannerState, bannerHasBeenShown: false });
        logoutRedirectRegular(true);
    }, [bannerState, logoutRedirectRegular, setBannerState]);

    return (
        <Button dataTestId="userMenuLogoutButton" onClick={logoutAndReturn} className={className} variant={variant}>
            {t('general.logout')}
        </Button>
    );
}

export default LogoutButton;
