import ApiError from 'classes/ApiError';
import DropdownInput from 'components/forms/DropdownInput';
import { LoadingIndicator } from 'components/indicators/LoadingIndicator';
import Page from 'components/layout/Page';
import InvoiceInfo from 'components/subscriptions/statistics/InvoiceInfo';
import { pageKeys } from 'constants/pageKeys';
import { useErrors } from 'contexts/ErrorContext';
import useBusinesses from 'hooks/useBusinesses';
import useFacilityStatistics from 'hooks/useFacilityStatistics';
import useGetBusiness from 'hooks/useGetBusiness';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { ChangeEvent, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { maxWidthContent, spaceM } from 'styles/variables';
import { ELanguage } from 'types';
import { Business, EBusinessFeature } from 'types/business';
import { ETrackingAction, ETrackingEvent, ETrackingOrigin } from 'types/tracking';

const Report = styled.div<{ $isReportLoading: boolean }>`
    position: relative;
    display: ${({ $isReportLoading }) => ($isReportLoading ? 'none' : 'unset')};
    .embededReport {
        max-width: ${maxWidthContent};
        height: 45rem;
    }
    iframe {
        border: none;
    }
`;

const StyledDropdownInput = styled(DropdownInput)`
    max-width: 18.75rem;
    margin-bottom: ${spaceM};
`;
function FacilityStatisticsPage(): JSX.Element {
    const { t, i18n } = useTranslation();
    const { businesses, error: errorBusinesses } = useBusinesses();
    const { getBusinessesListByFeature, getActiveBusiness } = useGetBusiness(businesses);
    const { errors: apiErrors, setErrors } = useErrors();

    let selectedOrganisation;

    const organisationList = getBusinessesListByFeature([
        EBusinessFeature.FACILITY_MANAGEMENT_EDIT,
        EBusinessFeature.FACILITY_MANAGEMENT_VIEW,
    ]);

    const parentBusiness = getActiveBusiness() as Business;

    if (
        organisationList.length > 1 &&
        !organisationList.some((org) => org.value === `${parentBusiness.id}_all`) &&
        parentBusiness.hasFacilityManagementInAllBusinesses
    ) {
        organisationList.unshift({ value: `${parentBusiness.id}_all`, text: t('general.all') });
        selectedOrganisation = `${parentBusiness.id}_all`;
    } else {
        selectedOrganisation = organisationList[0].value;
    }
    const { watch, setValue } = useForm({ defaultValues: { organisationDropdown: selectedOrganisation } });

    const {
        reportId,
        embedToken,
        embedUrl,
        removeFacilityStatisticsQueryKey,
        error: errorFacilityStatistics,
        isLoading,
    } = useFacilityStatistics({
        businessId:
            watch('organisationDropdown') === `${parentBusiness.id}_all`
                ? watch('organisationDropdown').split('_')[0]
                : watch('organisationDropdown'),
        allBusinesses: watch('organisationDropdown') === `${parentBusiness.id}_all`,
    });

    const [isReportLoading, setIsReportLoading] = useState<boolean>(true);

    const onOrganisationChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        setIsReportLoading(true);
        setValue('organisationDropdown', e.currentTarget.value);
        removeFacilityStatisticsQueryKey();
        ReactGA.event(ETrackingEvent.FILTER_ORGANISATION, {
            origin: ETrackingOrigin.FACILITY_STATISTICS,
            action: e.currentTarget.value === parentBusiness.id ? ETrackingAction.SHOW_ALL : ETrackingAction.SHOW_ONE,
        });
    };

    useEffect(() => {
        i18n.on('languageChanged', removeFacilityStatisticsQueryKey);
        return () => {
            i18n.off('languageChanged', removeFacilityStatisticsQueryKey);
        };
    }, [removeFacilityStatisticsQueryKey, i18n]);

    useEffect(() => {
        const errs: ApiError[] = [];
        if (errorFacilityStatistics) {
            errs.push(errorFacilityStatistics);
        }
        if (errorBusinesses) {
            errs.push(errorBusinesses);
        }
        setErrors(errs);
    }, [errorBusinesses, errorFacilityStatistics, setErrors]);

    useEffect(() => {
        if (isLoading) {
            setIsReportLoading(true);
        }
    }, [isLoading]);

    return (
        <Page
            pageKey={pageKeys.FACILITY_STATISTICS}
            dataTestId="facilityStatisticsPage"
            title={t('facility.statistics.title')}
            description={<InvoiceInfo origin={ETrackingOrigin.FACILITY_STATISTICS} />}
            errors={apiErrors}
        >
            {organisationList.length > 1 && (
                <StyledDropdownInput
                    dataTestId="organisationDropdown"
                    options={organisationList.map((business) => ({
                        value: business.value,
                        text: business.text,
                    }))}
                    name="organisationDropdown"
                    defaultValue={selectedOrganisation}
                    hidePlaceholderOption
                    onChange={onOrganisationChange}
                />
            )}
            {isReportLoading && <LoadingIndicator displayBorder={false} />}
            <Report data-testid="powerBIReport" $isReportLoading={isReportLoading}>
                {embedToken?.token && (
                    <PowerBIEmbed
                        key={embedToken.token}
                        embedConfig={{
                            type: 'report',
                            id: reportId,
                            embedUrl,
                            accessToken: embedToken?.token,
                            tokenType: models.TokenType.Embed,
                            settings: {
                                localeSettings: {
                                    language: localStorage.getItem('locale') ?? ELanguage['en-GB'],
                                },
                                navContentPaneEnabled: false,
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: false,
                                    },
                                },
                            },
                        }}
                        cssClassName="embededReport"
                        eventHandlers={
                            new Map([
                                [
                                    'rendered',
                                    function onRender(): void {
                                        setIsReportLoading(false);
                                    },
                                ],
                            ])
                        }
                    />
                )}
            </Report>
        </Page>
    );
}

export default FacilityStatisticsPage;
