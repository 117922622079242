import { GlobalState, useStateMachine } from 'little-state-machine';
import { ActionsOutput } from 'little-state-machine/dist/types';
import { IConnectorStatus } from 'types/facility';
import { MessageState } from 'types/inHubMessage';
import { IBulkSubscriptionType } from 'types/subscription';

export const setDontShowOnboardGuideAnymore = (state: GlobalState): GlobalState => {
    return {
        ...state,
        onboardingGuideHasBeenShown: true,
    };
};

export const setConnectorState = (state: GlobalState, payload: IConnectorStatus): GlobalState => {
    const localState = state;

    if (!localState.connectorState) {
        localState.connectorState = [];
    }

    const index = localState.connectorState.findIndex((connector) => connector.evseId === payload.evseId);

    if (index !== -1) {
        localState.connectorState.splice(index, 1);
    }
    localState.connectorState.push(payload);
    return {
        ...state,
        connectorState: localState.connectorState,
    };
};

export const setExpandibleMenu = (state: GlobalState, payload: string): GlobalState => {
    return {
        ...state,
        expandibleMenus: [...state.expandibleMenus.filter((menu) => menu !== payload).concat(payload)],
    };
};

export const removeExpandibleMenu = (state: GlobalState, payload: string): GlobalState => {
    return {
        ...state,
        expandibleMenus: [...state.expandibleMenus.filter((menu) => menu !== payload)],
    };
};

export const setSideMenuCollapsed = (state: GlobalState): GlobalState => {
    return {
        ...state,
        sideMenuExpanded: false,
    };
};

export const setSideMenuExpanded = (state: GlobalState): GlobalState => {
    return {
        ...state,
        sideMenuExpanded: true,
    };
};
export const setBulkImportSubscriptionOrder = (state: GlobalState, payload: IBulkSubscriptionType[]): GlobalState => {
    return {
        ...state,
        bulkImportSubscriptionOrder: payload,
    };
};

export const updateBulkImportSubscriptionOrder = (state: GlobalState, payload: IBulkSubscriptionType): GlobalState => {
    return {
        ...state,
        bulkImportSubscriptionOrder: state.bulkImportSubscriptionOrder.map((subscription) => {
            if (subscription.row === payload.row) {
                return payload;
            }
            return subscription;
        }),
    };
};

export const removeBulkImportSubscriptionOrder = (state: GlobalState, row: number): GlobalState => {
    return {
        ...state,
        bulkImportSubscriptionOrder: state.bulkImportSubscriptionOrder.filter(
            (order: IBulkSubscriptionType) => order.row !== row,
        ),
    };
};

export const removeAllBulkImportSubscriptionOrder = (state: GlobalState): GlobalState => {
    return {
        ...state,
        bulkImportSubscriptionOrder: [],
    };
};

export const setBannerState = (state: GlobalState, payload: MessageState): GlobalState => {
    return {
        ...state,
        bannerState: payload,
    };
};

type LocalStateAction<PayloadType = undefined> = (globalState: GlobalState, payload: PayloadType) => GlobalState;
type LocalStateActions = {
    setDontShowOnboardGuideAnymore: LocalStateAction;
    setConnectorState: LocalStateAction<IConnectorStatus>;
    setExpandibleMenu: LocalStateAction<string>;
    removeExpandibleMenu: LocalStateAction<string>;
    setBulkImportSubscriptionOrder: LocalStateAction<IBulkSubscriptionType[]>;
    removeBulkImportSubscriptionOrder: LocalStateAction<number>;
    removeAllBulkImportSubscriptionOrder: LocalStateAction<never>;
    updateBulkImportSubscriptionOrder: LocalStateAction<IBulkSubscriptionType>;
    setSideMenuCollapsed: LocalStateAction;
    setSideMenuExpanded: LocalStateAction;
    setBannerState: LocalStateAction<MessageState>;
};
const useLocalState = (): {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    actions: ActionsOutput<LocalStateAction, LocalStateActions>;
    state: GlobalState;
} =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useStateMachine<LocalStateAction, LocalStateActions>({
        setDontShowOnboardGuideAnymore,
        setConnectorState,
        setExpandibleMenu,
        removeExpandibleMenu,
        setBulkImportSubscriptionOrder,
        removeBulkImportSubscriptionOrder,
        removeAllBulkImportSubscriptionOrder,
        updateBulkImportSubscriptionOrder,
        setSideMenuCollapsed,
        setSideMenuExpanded,
        setBannerState,
    });
export default useLocalState;
