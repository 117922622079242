import { StyledButton } from 'components/clickables/BrowseFileButton';
import SectionPanel from 'components/elements/SectionPanel';
import Page from 'components/layout/Page';
import { pageKeys } from 'constants/pageKeys';
import useAuthentication from 'hooks/useAuthentication';
import useBusinesses from 'hooks/useBusinesses';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { font, screenWidthMedium, spaceL, spaceM, spaceS, spaceXs } from 'styles/variables';
import { ButtonSize, ButtonVariant } from 'types';
import { Business } from 'types/business';
import { ETrackingEvent, ETrackingOrigin } from 'types/tracking';

const Container = styled.section`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${spaceL};

    @media screen and (max-width: ${screenWidthMedium}) {
        grid-template-columns: 1fr;
        gap: ${spaceM};
    }
`;

const List = styled.dl`
    display: grid;
    grid-template-columns: 155px 1fr;
    gap: ${spaceXs} ${spaceS};
    line-height: ${font.lineHeight.l};
    dt {
        font-weight: ${font.weight.semiBold};
    }
`;

const Email = styled.dd`
    word-break: break-word;
`;

const OrganisationList = styled.ol`
    line-height: ${font.lineHeight.l};
    > li:not(:last-child) {
        margin-bottom: ${spaceM};
    }
    ol li {
        margin-bottom: ${spaceXs};
    }
`;

function MyAccountPage(): JSX.Element {
    const { t } = useTranslation();
    const { getAccountName, getAccountEmail, changeEmailRedirect } = useAuthentication();
    const name = getAccountName();
    const email = getAccountEmail();

    const { businesses } = useBusinesses();

    return (
        <Page pageKey={pageKeys.MY_ACCOUNT} title={t('myAccount.title')}>
            <Container>
                <SectionPanel
                    title={t('myAccount.accountSectionTitle')}
                    dataTestId="accountInformation"
                    button={
                        <StyledButton
                            dataTestId="changeEmailButton"
                            variant={ButtonVariant.SECONDARY}
                            size={ButtonSize.SMALL}
                            onClick={() => {
                                ReactGA.event(ETrackingEvent.CHANGE_EMAIL, {
                                    origin: ETrackingOrigin.MY_ACCOUNT,
                                });
                                changeEmailRedirect();
                            }}
                        >
                            {t('myAccount.changeEmail')}
                        </StyledButton>
                    }
                >
                    <List>
                        <dt>{t('myAccount.userName')}</dt>
                        <dd>{name}</dd>
                        <dt>{t('general.email')}</dt>
                        <Email>{email}</Email>
                    </List>
                </SectionPanel>
                <SectionPanel title={t('myAccount.organisationSectionTitle')} dataTestId="organisationInformation">
                    <OrganisationList>
                        {businesses.map((parentBusiness: Business) => (
                            <li key={parentBusiness.name}>
                                <ol>
                                    {parentBusiness.features && parentBusiness.features?.length > 0 && (
                                        <li>{parentBusiness.name}</li>
                                    )}
                                    {parentBusiness?.subsidiaries?.map(
                                        (sub) =>
                                            sub.features &&
                                            sub.features?.length > 0 && <li key={sub.id}>{sub.name}</li>,
                                    )}
                                </ol>
                            </li>
                        ))}
                    </OrganisationList>
                </SectionPanel>
            </Container>
        </Page>
    );
}

export default MyAccountPage;
