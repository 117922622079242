import DownloadIcon from 'assets/icons/download.svg?react';
import Button from 'components/clickables/Button';
import DropdownInput from 'components/forms/DropdownInput';
import StatusPill from 'components/info/StatusPill';
import { TextEllipsis } from 'components/info/TextEllipsisComponent';
import Page from 'components/layout/Page';
import GridList, { GridListHeader, GridListItem, Header } from 'components/lists/GridList';
import ListFooter from 'components/lists/ListFooter';
import { PAGE_SIZE } from 'constants/general';
import { pageKeys } from 'constants/pageKeys';
import useBusinesses from 'hooks/useBusinesses';
import { useCurrency } from 'hooks/useCurrency';
import useGetBusiness from 'hooks/useGetBusiness';
import useInvoicePdf from 'hooks/useInvoicePdf';
import useInvoices from 'hooks/useInvoices';
import ErrorPage from 'pages/ErrorPage';
import { ChangeEvent, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { font, spaceM, spaceS, spaceXl } from 'styles/variables';
import { ButtonSize, ButtonVariant, EPageErrorType } from 'types';
import { EBusinessFeature } from 'types/business';
import { ETrackingEvent, ETrackingOrigin, ETrackingType } from 'types/tracking';

const gridColumn = 'repeat(4, 1fr) 0.5fr';

const UtilityBar = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 500px) max-content;
    gap: ${spaceS} ${spaceM};
    margin-bottom: ${spaceM};
`;

const DownloadButton = styled(Button)`
    justify-self: flex-start;
    gap: 0.25rem;
    &.btn--size-small {
        border-radius: 0.5rem;
        border-width: 2px;
    }

    > svg {
        width: ${font.size.l};
        height: ${font.size.l};
    }
`;

const StyledDropdownInput = styled(DropdownInput)`
    max-width: 18.75rem;
    margin-bottom: 0;
`;

const Amount = styled(TextEllipsis)`
    text-align: right;
`;

const StyledGridList = styled(GridList)`
    li {
        gap: ${spaceXl};
    }
`;

const AmountHeader = styled(Header)`
    text-align: right;
`;

function BillingInvoicePage(): JSX.Element {
    const { t } = useTranslation();
    const { toCurrency } = useCurrency();
    const [pageNumber, setPageNumber] = useState<number>(0);

    const { businesses, isLoading: isLoadingBusinesses, error: errorBusinesses } = useBusinesses();
    const { getBusinessesListByFeature } = useGetBusiness(businesses);

    const orgsInvoicesVisible = getBusinessesListByFeature([EBusinessFeature.INVOICES_MANAGEMENT_VIEW], true);

    const organisationList = orgsInvoicesVisible.length > 1 ? orgsInvoicesVisible.slice(1) : orgsInvoicesVisible;
    const selectedOrganisationId = organisationList[0].value;

    const [selectedOrganisation, setSelectedOrganisation] = useState<string>(selectedOrganisationId);

    const orderQueryData = useMemo(() => {
        return { businessId: selectedOrganisation, pageNumber, pageSize: PAGE_SIZE };
    }, [selectedOrganisation, pageNumber]);
    const { invoicesResponse, isLoading: isLoadingInvoices, error: errorInvoices } = useInvoices(orderQueryData);
    const getInvoicePdf = useInvoicePdf();

    const onOrganisationChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        setSelectedOrganisation(e.currentTarget.value);
    };

    const OrganisationsDropDown =
        organisationList.length > 1 ? (
            <StyledDropdownInput
                dataTestId="organisationDropdown"
                options={organisationList.map((business) => ({
                    value: business.value,
                    text: business.text,
                }))}
                name="organisationDropdown"
                defaultValue={selectedOrganisationId}
                hidePlaceholderOption
                hideOptionalText
                onChange={onOrganisationChange}
            />
        ) : undefined;

    const fetchError = errorBusinesses || errorInvoices;

    const apiErrors = [];
    if (!isLoadingBusinesses && fetchError) {
        apiErrors.push(fetchError);
    }

    const handlePagination = (page: number): void => {
        setPageNumber(page);
    };

    return (
        <Page
            pageKey={pageKeys.INVOICES}
            title={t('invoices.title')}
            dataTestId="billingInvoicePage"
            isLoading={isLoadingBusinesses}
            errors={apiErrors}
        >
            <UtilityBar>{OrganisationsDropDown}</UtilityBar>
            <StyledGridList
                showEmptyListMessage={invoicesResponse?.invoices.length === 0 && !isLoadingBusinesses}
                gridColumns={gridColumn}
                dataTestId="billingInvoiceTable"
                isLoading={isLoadingInvoices}
            >
                <GridListHeader>
                    <Header>{t('general.status')}</Header>
                    <Header>{t('invoices.invoiceTable.invoiceNo')}</Header>
                    <Header>{t('general.date')}</Header>
                    <AmountHeader>{t('invoices.invoiceTable.amount')}</AmountHeader>
                    <Header>{t('invoices.invoiceTable.invoice')}</Header>
                </GridListHeader>
                {invoicesResponse?.invoices.map((invoice) => {
                    const { invoiceNumber, paymentStatus, creationDate, amountExcludingVat, currency } = invoice;
                    return (
                        <GridListItem key={invoiceNumber}>
                            <StatusPill status={paymentStatus} />
                            <TextEllipsis>{invoiceNumber}</TextEllipsis>
                            <TextEllipsis>{creationDate}</TextEllipsis>
                            <Amount>
                                {toCurrency({
                                    amount: amountExcludingVat,
                                    currency,
                                })}
                            </Amount>
                            <DownloadButton
                                Icon={DownloadIcon}
                                variant={ButtonVariant.SECONDARY}
                                size={ButtonSize.SMALL}
                                dataTestId="downloadPdfButton"
                                onClick={() => {
                                    getInvoicePdf({
                                        invoiceNumber,
                                        creationDate,
                                    });
                                    ReactGA.event(ETrackingEvent.EXPORT_FILE, {
                                        origin: ETrackingOrigin.INVOICES,
                                        type: ETrackingType.INVOICE,
                                    });
                                }}
                            >
                                {t('general.pdf')}
                            </DownloadButton>
                        </GridListItem>
                    );
                })}
                {fetchError && <ErrorPage type={EPageErrorType.TEMPORARY} displayBorder={false} />}
            </StyledGridList>
            {!isLoadingBusinesses && invoicesResponse && (
                <ListFooter data={invoicesResponse} handlePagination={handlePagination} pageNumber={pageNumber} />
            )}
        </Page>
    );
}

export default BillingInvoicePage;
